import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/landing',
    pathMatch: 'full',
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: 'register-verification',
    loadChildren: () =>
      import('./register-verification/register-verification.module').then(
        (m) => m.RegisterVerificationPageModule
      ),
    canActivate: [GuestGuard],
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup-phone/signup-phone.module').then(
        (m) => m.SignupPhonePageModule
      ),
    canActivate: [GuestGuard],
  },
  {
    path: 'signup-verification',
    loadChildren: () =>
      import('./signup-verification/signup-verification.module').then(
        (m) => m.SignupVerificationPageModule
      ),
    canActivate: [GuestGuard],
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./sign-in/sign-in.module').then((m) => m.SignInPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: 'intro1',
    loadChildren: () =>
      import('./intro1/intro1.module').then((m) => m.Intro1PageModule),
    canActivate: [GuestGuard],
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingPageModule),
    canActivate: [GuestGuard],
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUsePageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },

  {
    path: 'guest-view',
    loadChildren: () =>
      import('./guest-view/guest-view.module').then(
        (m) => m.GuestViewPageModule
      ),
    canActivate: [GuestGuard],
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },

  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
    canActivate: [GuestGuard],
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
  {
    path: 'share',
    loadChildren: () =>
      import('./modal/share/share.module').then((m) => m.SharePageModule),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
