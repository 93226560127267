import { Injectable, EventEmitter } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

import {
  NebulaeIdentityService,
  NebulaeLoyaltyService,
  NebulaeLoyaltyMemberService,
  IdentityVerificationMethodType,
  IdentityPreVerifyType,
  LoyaltyMemberUpdateInput,
} from '@nebulaeapp/nebulae-sdk-angular';
import { HttpClient } from '@angular/common/http';

const PROGRAM_ID = environment.program_id;
@Injectable({
  providedIn: 'root',
})
export class DataService {
  static SIGNUP_DATA = 'sign_up_data';

  loginEvent = new EventEmitter();
  permissionEvent = new EventEmitter();
  userNameEvent = new EventEmitter();
  shareModal = new EventEmitter();
  registerDataDetail: any = {};

  countryCodes = [
    {
      code: '+852',
      name: 'Hong Kong',
      native: '香港',
    },
    {
      code: '+853',
      name: 'Macau',
      native: '澳門',
    },
    {
      code: '+86',
      name: 'China',
      native: '中国',
    },
    {
      code: '+93',
      name: 'Afghanistan',
      native: 'افغانستان',
    },
    {
      code: '+355',
      name: 'Albania',
      native: 'Shqipëria',
    },
    {
      code: '+213',
      name: 'Algeria',
      native: 'الجزائر',
    },
    {
      code: '+376',
      name: 'Andorra',
      native: 'Andorra',
    },
    {
      code: '+244',
      name: 'Angola',
      native: 'Angola',
    },
    {
      code: '+1',
      name: 'Antigua and Barbuda',
      native: 'Antigua and Barbuda',
    },
    {
      code: '+54',
      name: 'Argentina',
      native: 'Argentina',
    },
    {
      code: '+374',
      name: 'Armenia',
      native: 'Հայաստան',
    },
    {
      code: '+61',
      name: 'Australia',
      native: 'Australia',
    },
    {
      code: '+43',
      name: 'Austria',
      native: 'Österreich',
    },
    {
      code: '+994',
      name: 'Azerbaijan',
      native: 'Azərbaycan',
    },
    {
      code: '+1',
      name: 'Bahamas',
      native: 'Bahamas',
    },
    {
      code: '+973',
      name: 'Bahrain',
      native: 'البحرين',
    },
    {
      code: '+880',
      name: 'Bangladesh',
      native: 'বাংলাদেশ',
    },
    {
      code: '+1',
      name: 'Barbados',
      native: 'Barbados',
    },
    {
      code: '+375',
      name: 'Belarus',
      native: 'Беларусь',
    },
    {
      code: '+32',
      name: 'Belgium',
      native: 'België',
    },
    {
      code: '+501',
      name: 'Belize',
      native: 'Belize',
    },
    {
      code: '+229',
      name: 'Benin',
      native: 'Bénin',
    },
    {
      code: '+975',
      name: 'Bhutan',
      native: 'འབྲུག་ཡུལ་',
    },
    {
      code: '+591',
      name: 'Bolivia',
      native: 'Bolivia',
    },
    {
      code: '+387',
      name: 'Bosnia and Herzegovina',
      native: 'Bosna i Hercegovina',
    },
    {
      code: '+267',
      name: 'Botswana',
      native: 'Botswana',
    },
    {
      code: '+55',
      name: 'Brazil',
      native: 'Brasil',
    },
    {
      code: '+673',
      name: 'Brunei',
      native: 'Negara Brunei Darussalam',
    },
    {
      code: '+359',
      name: 'Bulgaria',
      native: 'България',
    },
    {
      code: '+226',
      name: 'Burkina Faso',
      native: 'Burkina Faso',
    },
    {
      code: '+257',
      name: 'Burundi',
      native: 'Burundi',
    },
    {
      code: '+855',
      name: 'Cambodia',
      native: 'កម្ពុជា',
    },
    {
      code: '+237',
      name: 'Cameroon',
      native: 'Cameroon',
    },
    {
      code: '+1',
      name: 'Canada',
      native: 'Canada',
    },
    {
      code: '+238',
      name: 'Cape Verde',
      native: 'Cabo Verde',
    },
    {
      code: '+236',
      name: 'Central African Republic',
      native: 'Ködörösêse tî Bêafrîka',
    },
    {
      code: '+235',
      name: 'Chad',
      native: 'Tchad',
    },
    {
      code: '+56',
      name: 'Chile',
      native: 'Chile',
    },
    {
      code: '+57',
      name: 'Colombia',
      native: 'Colombia',
    },
    {
      code: '+269',
      name: 'Comoros',
      native: 'Komori',
    },
    {
      code: '+242',
      name: 'Congo',
      native: 'République du Congo',
    },
    {
      code: '+243',
      name: 'Democratic Republic of the Congo',
      native: 'République démocratique du Congo',
    },
    {
      code: '+506',
      name: 'Costa Rica',
      native: 'Costa Rica',
    },
    {
      code: '+385',
      name: 'Croatia',
      native: 'Hrvatska',
    },
    {
      code: '+53',
      name: 'Cuba',
      native: 'Cuba',
    },
    {
      code: '+357',
      name: 'Cyprus',
      native: 'Κύπρος',
    },
    {
      code: '+420',
      name: 'Czech Republic',
      native: 'Česká republika',
    },
    {
      code: '+45',
      name: 'Denmark',
      native: 'Danmark',
    },
    {
      code: '+253',
      name: 'Djibouti',
      native: 'Djibouti',
    },
    {
      code: '+1',
      name: 'Dominica',
      native: 'Dominica',
    },
    {
      code: '+1',
      name: 'Dominican Republic',
      native: 'República Dominicana',
    },
    {
      code: '+593',
      name: 'Ecuador',
      native: 'Ecuador',
    },
    {
      code: '+20',
      name: 'Egypt',
      native: 'مصر‎',
    },
    {
      code: '+503',
      name: 'El Salvador',
      native: 'El Salvador',
    },
    {
      code: '+240',
      name: 'Equatorial Guinea',
      native: 'Guinea Ecuatorial',
    },
    {
      code: '+291',
      name: 'Eritrea',
      native: 'ኤርትራ',
    },
    {
      code: '+372',
      name: 'Estonia',
      native: 'Eesti',
    },
    {
      code: '+251',
      name: 'Ethiopia',
      native: 'ኢትዮጵያ',
    },
    {
      code: '+679',
      name: 'Fiji',
      native: 'Fiji',
    },
    {
      code: '+358',
      name: 'Finland',
      native: 'Suomi',
    },
    {
      code: '+33',
      name: 'France',
      native: 'France',
    },
    {
      code: '+241',
      name: 'Gabon',
      native: 'Gabon',
    },
    {
      code: '+220',
      name: 'Gambia',
      native: 'Gambia',
    },
    {
      code: '+995',
      name: 'Georgia',
      native: 'საქართველო',
    },
    {
      code: '+49',
      name: 'Germany',
      native: 'Deutschland',
    },
    {
      code: '+233',
      name: 'Ghana',
      native: 'Ghana',
    },
    {
      code: '+30',
      name: 'Greece',
      native: 'Ελλάδα',
    },
    {
      code: '+1',
      name: 'Grenada',
      native: 'Grenada',
    },
    {
      code: '+502',
      name: 'Guatemala',
      native: 'Guatemala',
    },
    {
      code: '+224',
      name: 'Guinea',
      native: 'Guinée',
    },
    {
      code: '+245',
      name: 'Guinea-Bissau',
      native: 'Guiné-Bissau',
    },
    {
      code: '+592',
      name: 'Guyana',
      native: 'Guyana',
    },
    {
      code: '+509',
      name: 'Haiti',
      native: 'Haïti',
    },
    {
      code: '+504',
      name: 'Honduras',
      native: 'Honduras',
    },
    {
      code: '+36',
      name: 'Hungary',
      native: 'Magyarország',
    },
    {
      code: '+354',
      name: 'Iceland',
      native: 'Ísland',
    },
    {
      code: '+91',
      name: 'India',
      native: 'भारत',
    },
    {
      code: '+62',
      name: 'Indonesia',
      native: 'Indonesia',
    },
    {
      code: '+98',
      name: 'Iran',
      native: 'ایران',
    },
    {
      code: '+964',
      name: 'Iraq',
      native: 'العراق',
    },
    {
      code: '+353',
      name: 'Ireland',
      native: 'Éire',
    },
    {
      code: '+972',
      name: 'Israel',
      native: 'יִשְׂרָאֵל',
    },
    {
      code: '+39',
      name: 'Italy',
      native: 'Italia',
    },
    {
      code: '+1',
      name: 'Jamaica',
      native: 'Jamaica',
    },
    {
      code: '+81',
      name: 'Japan',
      native: '日本',
    },
    {
      code: '+962',
      name: 'Jordan',
      native: 'الأردن',
    },
    {
      code: '+7',
      name: 'Kazakhstan',
      native: 'Қазақстан',
    },
    {
      code: '+254',
      name: 'Kenya',
      native: 'Kenya',
    },
    {
      code: '+686',
      name: 'Kiribati',
      native: 'Kiribati',
    },
    {
      code: '+965',
      name: 'Kuwait',
      native: 'الكويت',
    },
    {
      code: '+996',
      name: 'Kyrgyzstan',
      native: 'Кыргызстан',
    },
    {
      code: '+856',
      name: 'Laos',
      native: 'ລາວ',
    },
    {
      code: '+371',
      name: 'Latvia',
      native: 'Latvija',
    },
    {
      code: '+961',
      name: 'Lebanon',
      native: 'لبنان',
    },
    {
      code: '+266',
      name: 'Lesotho',
      native: 'Lesotho',
    },
    {
      code: '+231',
      name: 'Liberia',
      native: 'Liberia',
    },
    {
      code: '+218',
      name: 'Libya',
      native: 'ليبيا',
    },
    {
      code: '+423',
      name: 'Liechtenstein',
      native: 'Liechtenstein',
    },
    {
      code: '+370',
      name: 'Lithuania',
      native: 'Lietuva',
    },
    {
      code: '+352',
      name: 'Luxembourg',
      native: 'Luxembourg',
    },
    {
      code: '+389',
      name: 'North Macedonia',
      native: 'Северна Македонија',
    },
    {
      code: '+261',
      name: 'Madagascar',
      native: 'Madagasikara',
    },
    {
      code: '+265',
      name: 'Malawi',
      native: 'Malawi',
    },
    {
      code: '+60',
      name: 'Malaysia',
      native: 'Malaysia',
    },
    {
      code: '+960',
      name: 'Maldives',
      native: 'Maldives',
    },
    {
      code: '+223',
      name: 'Mali',
      native: 'Mali',
    },
    {
      code: '+356',
      name: 'Malta',
      native: 'Malta',
    },
    {
      code: '+692',
      name: 'Marshall Islands',
      native: 'M̧ajeļ',
    },
    {
      code: '+222',
      name: 'Mauritania',
      native: 'موريتانيا',
    },
    {
      code: '+230',
      name: 'Mauritius',
      native: 'Maurice',
    },
    {
      code: '+52',
      name: 'Mexico',
      native: 'México',
    },
    {
      code: '+691',
      name: 'Micronesia',
      native: 'Micronesia',
    },
    {
      code: '+373',
      name: 'Moldova',
      native: 'Moldova',
    },
    {
      code: '+377',
      name: 'Monaco',
      native: 'Monaco',
    },
    {
      code: '+976',
      name: 'Mongolia',
      native: 'Монгол улс',
    },
    {
      code: '+382',
      name: 'Montenegro',
      native: 'Црна Гора',
    },
    {
      code: '+212',
      name: 'Morocco',
      native: 'المغرب',
    },
    {
      code: '+258',
      name: 'Mozambique',
      native: 'Moçambique',
    },
    {
      code: '+95',
      name: 'Myanmar',
      native: 'Myanmar (Burma)',
    },
    {
      code: '+264',
      name: 'Namibia',
      native: 'Namibia',
    },
    {
      code: '+674',
      name: 'Nauru',
      native: 'Nauru',
    },
    {
      code: '+977',
      name: 'Nepal',
      native: 'नेपाल',
    },
    {
      code: '+31',
      name: 'Netherlands',
      native: 'Nederland',
    },
    {
      code: '+64',
      name: 'New Zealand',
      native: 'Aotearoa',
    },
    {
      code: '+505',
      name: 'Nicaragua',
      native: 'Nicaragua',
    },
    {
      code: '+227',
      name: 'Niger',
      native: 'Niger',
    },
    {
      code: '+234',
      name: 'Nigeria',
      native: 'Nigeria',
    },
    {
      code: '+850',
      name: 'North Korea',
      native: '조선민주주의인민공화국',
    },
    {
      code: '+47',
      name: 'Norway',
      native: 'Norge',
    },
    {
      code: '+968',
      name: 'Oman',
      native: 'عمان',
    },
    {
      code: '+92',
      name: 'Pakistan',
      native: 'پاکستان',
    },
    {
      code: '+680',
      name: 'Palau',
      native: 'Palau',
    },
    {
      code: '+507',
      name: 'Panama',
      native: 'Panamá',
    },
    {
      code: '+675',
      name: 'Papua New Guinea',
      native: 'Papua Niugini',
    },
    {
      code: '+595',
      name: 'Paraguay',
      native: 'Paraguay',
    },
    {
      code: '+51',
      name: 'Peru',
      native: 'Perú',
    },
    {
      code: '+63',
      name: 'Philippines',
      native: 'Pilipinas',
    },
    {
      code: '+48',
      name: 'Poland',
      native: 'Polska',
    },
    {
      code: '+351',
      name: 'Portugal',
      native: 'Portugal',
    },
    {
      code: '+974',
      name: 'Qatar',
      native: 'قطر',
    },
    {
      code: '+40',
      name: 'Romania',
      native: 'România',
    },
    {
      code: '+7',
      name: 'Russia',
      native: 'Россия',
    },
    {
      code: '+250',
      name: 'Rwanda',
      native: 'Rwanda',
    },
    {
      code: '+1',
      name: 'Saint Kitts and Nevis',
      native: 'Saint Kitts and Nevis',
    },
    {
      code: '+1',
      name: 'Saint Lucia',
      native: 'Saint Lucia',
    },
    {
      code: '+1',
      name: 'Saint Vincent and the Grenadines',
      native: 'Saint Vincent and the Grenadines',
    },
    {
      code: '+685',
      name: 'Samoa',
      native: 'Samoa',
    },
    {
      code: '+378',
      name: 'San Marino',
      native: 'San Marino',
    },
    {
      code: '+239',
      name: 'Sao Tome and Principe',
      native: 'São Tomé e Príncipe',
    },
    {
      code: '+966',
      name: 'Saudi Arabia',
      native: 'العربية السعودية',
    },
    {
      code: '+221',
      name: 'Senegal',
      native: 'Sénégal',
    },
    {
      code: '+381',
      name: 'Serbia',
      native: 'Србија',
    },
    {
      code: '+248',
      name: 'Seychelles',
      native: 'Seychelles',
    },
    {
      code: '+232',
      name: 'Sierra Leone',
      native: 'Sierra Leone',
    },
    {
      code: '+65',
      name: 'Singapore',
      native: 'Singapore',
    },
    {
      code: '+421',
      name: 'Slovakia',
      native: 'Slovensko',
    },
    {
      code: '+386',
      name: 'Slovenia',
      native: 'Slovenija',
    },
    {
      code: '+677',
      name: 'Solomon Islands',
      native: 'Solomon Islands',
    },
    {
      code: '+252',
      name: 'Somalia',
      native: 'Somalia',
    },
    {
      code: '+27',
      name: 'South Africa',
      native: 'South Africa',
    },
    {
      code: '+82',
      name: 'South Korea',
      native: '대한민국',
    },
    {
      code: '+211',
      name: 'South Sudan',
      native: 'South Sudan',
    },
    {
      code: '+34',
      name: 'Spain',
      native: 'España',
    },
    {
      code: '+94',
      name: 'Sri Lanka',
      native: 'ශ්‍රී ලංකාව',
    },
    {
      code: '+249',
      name: 'Sudan',
      native: 'السودان',
    },
    {
      code: '+597',
      name: 'Suriname',
      native: 'Suriname',
    },
    {
      code: '+46',
      name: 'Sweden',
      native: 'Sverige',
    },
    {
      code: '+41',
      name: 'Switzerland',
      native: 'Schweiz',
    },
    {
      code: '+963',
      name: 'Syria',
      native: 'سوريا',
    },
    {
      code: '+886',
      name: 'Taiwan',
      native: '臺灣',
    },
    {
      code: '+992',
      name: 'Tajikistan',
      native: 'Тоҷикистон',
    },
    {
      code: '+255',
      name: 'Tanzania',
      native: 'Tanzania',
    },
    {
      code: '+66',
      name: 'Thailand',
      native: 'ประเทศไทย',
    },
    {
      code: '+670',
      name: 'Timor-Leste',
      native: 'Timor-Leste',
    },
    {
      code: '+228',
      name: 'Togo',
      native: 'Togo',
    },
    {
      code: '+676',
      name: 'Tonga',
      native: 'Tonga',
    },
    {
      code: '+1',
      name: 'Trinidad and Tobago',
      native: 'Trinidad and Tobago',
    },
    {
      code: '+216',
      name: 'Tunisia',
      native: 'تونس',
    },
    {
      code: '+90',
      name: 'Turkey',
      native: 'Türkiye',
    },
    {
      code: '+993',
      name: 'Turkmenistan',
      native: 'Türkmenistan',
    },
    {
      code: '+688',
      name: 'Tuvalu',
      native: 'Tuvalu',
    },
    {
      code: '+256',
      name: 'Uganda',
      native: 'Uganda',
    },
    {
      code: '+380',
      name: 'Ukraine',
      native: 'Україна',
    },
    {
      code: '+971',
      name: 'United Arab Emirates',
      native: 'الإمارات العربية المتحدة',
    },
    {
      code: '+44',
      name: 'United Kingdom',
      native: 'United Kingdom',
    },
    {
      code: '+1',
      name: 'United States',
      native: 'United States',
    },
    {
      code: '+598',
      name: 'Uruguay',
      native: 'Uruguay',
    },
    {
      code: '+998',
      name: 'Uzbekistan',
      native: 'O‘zbekiston',
    },
    {
      code: '+678',
      name: 'Vanuatu',
      native: 'Vanuatu',
    },
    {
      code: '+379',
      name: 'Vatican City',
      native: 'Città del Vaticano',
    },
    {
      code: '+58',
      name: 'Venezuela',
      native: 'Venezuela',
    },
    {
      code: '+84',
      name: 'Vietnam',
      native: 'Việt Nam',
    },
    {
      code: '+967',
      name: 'Yemen',
      native: 'اليَمَن',
    },
    {
      code: '+260',
      name: 'Zambia',
      native: 'Zambia',
    },
    {
      code: '+263',
      name: 'Zimbabwe',
      native: 'Zimbabwe',
    },
  ];

  constructor(
    public storage: Storage,
    private identity: NebulaeIdentityService,
    private loyaltyMember: NebulaeLoyaltyMemberService,
    private http: HttpClient
  ) {
    this.init();
  }

  async init() {
    return new Promise(async (resolve, reject) => {
      await this.storage.create();
      resolve(true);
    });
  }

  // set local storage
  public async set(key: string, value: any) {
    await this.storage.set(key, value);
    return value;
  }
  //get local storage
  async get(key: string) {
    return new Promise(async (resolve, reject) => {
      await this.storage.get(key).then((res) => {
        resolve(res);
      });
    });
  }

  // clear localstorage
  async clearLocalStorage() {
    await this.storage.remove('user_type');
    await this.storage.remove('refresh_token');
    await this.storage.remove('user_id');
    await this.storage.remove(DataService.SIGNUP_DATA);
  }

  //HOME NEWS LIST
  async getNewsList(): Promise<any> {
    try {
      const result = await this.loyaltyMember.news.list({
        program_id: environment.program_id,
        type: 'news' as any,
        sort: { ordering: 'asc' } as any,
      });

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //Contact Page
  async submitContact(data): Promise<any> {
    let new_data: any = {
      program_id: environment.program_id,
      firstname: data.first_name,
      lastname: data.last_name,
      phone: {
        country_code: data.country_code.substring(1),
        phone_number: data.phone,
      },
      email: data.email,
      message: data.feedback,
      preferred_language: data.preferred_language,
    };
    try {
      const result = await this.loyaltyMember.enquiry_tickets.create(new_data);
      console.log('contact result', result);
      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //GET NOTIFICATION LIST
  async getNotificationList(limit, skip): Promise<any> {
    this.loadToken();
    try {
      const result = await this.loyaltyMember.inbox_messages.list({
        limit: limit,
        skip: skip,
      });
      // this.loyaltyMember.inbox_messages

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  async loadToken() {
    try {
      const access_token =
        this.loyaltyMember.getAuthorizationContent().access_token;
      this.loyaltyMember.setAuthorization({ bearer: access_token });
    } catch (error) {
      // this.logger.debug(`loadToken|error: ${JSON.stringify(error)}`)
    }
  }

  //GET NOTIFICATION LIST
  async getNotificationDetail(id): Promise<any> {
    try {
      this.loadToken();
      const result = await this.loyaltyMember.inbox_messages.retrieve(id);

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //GET REDEEM LIST
  async getRedeemList(limit, skip): Promise<any> {
    const data = {
      program_id: PROGRAM_ID,
      limit: limit,
      skip: skip,
    };
    try {
      const result = await this.loyaltyMember.rewards.list(data);

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //GET MEMBER POINTS
  async getMemberPoints(): Promise<any> {
    try {
      const result =
        await this.loyaltyMember.point_transactions.member_total_points();

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //GET MEMBERSHIP TIER POINTS
  async getMemberTierList(): Promise<any> {
    try {
      const result = await this.loyaltyMember.membership_tiers.list({
        program_id: PROGRAM_ID,
        sort: { tier_level: 'asc' } as any,
      });

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //GET Meber Spending POints
  async getMemberSpending(): Promise<any> {
    try {
      const result = await this.loyaltyMember.member.accumulated_spending();

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  // POST Update Memeber
  async updateMember(data): Promise<any> {
    try {
      let dob: any = {};
      if (data.dob) {
        dob.year = parseInt(moment(data.dob).format('YYYY'));
        dob.month = parseInt(moment(data.dob).format('MM'));
        dob.day = parseInt(moment(data.dob).format('DD'));
      }
      let basic_info: any = {
        name: {
          eng: {
            firstname: data.first_name,
            lastname: data.last_name,
          },
        },
        phone: data.phone,
        born_on: dob,
        email: data.email,
        password: data.password,
        // verification_method: IdentityVerificationMethodType.SMS_OTP,
        referrer_code: data.referrer_code,
        preferred_language: data.preferred_language,
      };
      basic_info.attributes = {};

      if (data.skin) {
        const skin_key = data.skin;
        const lower_skin_key = skin_key.toLowerCase();
        const new_skin_key = lower_skin_key.replace(/ /g, '_');
        basic_info.attributes.SKIN_TYPE = new_skin_key;
      }

      // if (data.marketing_type.includes('Facebook')) {
      //   basic_info.attributes.MARKETING_OPT_OUT_FACEBOOK = true;
      // }
      // if (data.marketing_type.includes('Google')) {
      //   basic_info.attributes.MARKETING_OPT_OUT_GOOGLE = true;
      // }
      // if (data.marketing_type.includes('Instagram')) {
      //   basic_info.attributes.MARKETING_OPT_OUT_INSTAGRAM = true;
      // }
      basic_info.attributes.MARKETING_OPT_OUT = data.marketing_opt_out;

      if (data.gender) {
        basic_info.gender = data.gender;
      }
      if (data.channel) {
        basic_info.attributes.channel = data.channel.toLowerCase();
      }
      if (data.content) {
        basic_info.attributes.content = data.content.toLowerCase();
      }
      if (data.campaign) {
        basic_info.attributes.campaign = data.campaign.toLowerCase();
      }

      console.log('basic_info::', basic_info);

      const result = await this.loyaltyMember.member.update_profile(basic_info);
      return Promise.resolve(result);
    } catch (error: any) {
      return Promise.reject(error.body?.error ?? error.body ?? error);
      // if (error.body && error.body.error) {
      //   return Promise.reject(error.body.error.message);
      // } else {
      //   return Promise.reject(error);
      // }
    }
  }

  //GET MEMBER DETAIL
  async getMemberDetail(): Promise<any> {
    try {
      const result = await this.loyaltyMember.member.get_profile();

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //PASSWOFD RESET CODE
  async forgotPasswordEmail(email, lang?: string): Promise<any> {
    try {
      await this.identity.auth.forgotPassword({
        email: email,
        verification_method: IdentityVerificationMethodType.EMAIL_OTP,
        lang: lang,
      });
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //Pre verify PASSWORD RESET code
  async verifyActivationCode(code: string, email): Promise<any> {
    try {
      let data = {
        code: code,
        email: email,
        pre_verify_type: IdentityPreVerifyType.RESET_PASSWORD,
        client_id: environment.client_id,
        verification_method: IdentityVerificationMethodType.SMS_OTP,
      };
      return await this.identity.auth.preVerify(data);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  // forgot reset password
  async resetPassword(code: string, password: string): Promise<any> {
    try {
      return await this.identity.auth.resetPassword({
        code: code,
        new_password: password,
      });
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  async getWalletPass(member_id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const token = await this.loyaltyMember.getAuthorization();

        await this.http
          .get(
            `https://${environment.api_v2_config.api_host}/${environment.api_v2_config.api_path}/wallet/v1/passes/${member_id}`,
            {
              headers: {
                Authorization: `Bearer ${token.bearer}`,
              },
              responseType: 'arraybuffer',
            }
          )
          .subscribe(
            (response: any) => {
              let blob = new Blob([response], {
                type: 'application/vnd.apple.pkpass',
              });
              let downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(blob);
              downloadLink.setAttribute('download', 'tatcha-loyalty');
              document.body.appendChild(downloadLink);
              downloadLink.click();
              resolve(true);
            },
            (error) => {
              console.log('error', error);
              reject(error);
              throw Error(error);
            }
          );
      } catch (error: any) {
        reject(error);
      }
    });
  }
}
