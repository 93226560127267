import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss'],
})
export class ReasonModalComponent implements OnInit {

  @Input() reason: any;
  @Input() selectedReason:string = '';
  constructor(public modalCtrl: ModalController) { 
    
  }

  ngOnInit() {
    
  }

  async closeModal(){
    await this.modalCtrl.dismiss()
  }

  async selectReason(){
    
    await this.modalCtrl.dismiss({reason: this.selectedReason})
  }

}
