import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {

  @Input() message: any;
  @Input() success: any;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  async closeModal(){
    await this.modalCtrl.dismiss();
  }

}
