<ion-content class="event-content">
  <ion-item class="title-header">
    <ion-button (click)="closeScanner()" class="back-btn" slot="start" fill="clear">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-button>
    <h2>
      <b *ngIf="title">{{title}}</b>
      <b *ngIf="!title">Scan</b>
    </h2>
   
  </ion-item>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div id="modalScanner"></div>
      </ion-col> 
    </ion-row>
  </ion-grid>
</ion-content>
