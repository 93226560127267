import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
// import { SeoService } from "./utils/seo/seo.service";
const { SplashScreen } = Plugins;
import { TranslateService } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { Router } from '@angular/router';
import { MenuController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss',
  ],
})
export class AppComponent {
  textDir = 'ltr';

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public loadingCtrl: LoadingController,
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    // private seoService: SeoService,
    public router: Router,
    public storage: Storage,
    public ds: DataService,
    public menuCtrl: MenuController,
    public authService: AuthService
  ) {
    this.initializeApp();
    this.ds.get('lang').then((type) => {
      if (!type) {
        this.setLanguage('en_us');
      } else {
        this.setLanguage(type);
      }
    });
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
    await this.ds.init();
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  async setLanguage(langType) {
    // this language will be used as a fallback when a translation isn't found in the current language
    await this.translate.setDefaultLang(langType);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    await this.translate.use(langType);

    await this.ds.set('lang', langType);

    this.menuCtrl.close();
  }

  async logout() {
    const loadingMessage = await this.translate.get('PLEASE_WAIT').toPromise();

    const loading = await this.loadingCtrl.create({
      message: loadingMessage,
      cssClass: 'theme-loading',
    });
    await loading.present();

    try {
      let user_id = await this.ds.get('user_id');
      let refresh_token = await this.ds.get('refresh_token');
      await this.authService.signOut(user_id, refresh_token);

      await localStorage.clear();
      await this.storage.clear();
    } catch (error) {
      console.log(error);
    } finally {
      let clear = await this.ds.clearLocalStorage();
      this.router.navigate(['sign-in'], { replaceUrl: true });
      await loading.dismiss();
    }
  }

  openLink(type) {
    if (type == 'product') {
      let cl = this.translate.currentLang;
      let url;
      if (cl == 'en_us') {
        // url = "https://www.tatcha.com/category/shop-all/?page=1";
        url =
          'https://www.lanecrawford.com.hk/brand/27700002/tatcha/beauty/?query=TATCHA';
      } else if (cl == 'zh_hk') {
        url = 'https://hk.tatcha.com/product-listing/products';
      } else if (cl == 'zh_cn') {
        url = 'https://cn.tatcha.com/product-listing/products';
      }
      if (url) {
        window.open(url, '_self');
      }
    }
  }
}
