// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import {
  NebulaeHost,
  NebulaeSdkServiceConfig,
} from "@nebulaeapp/nebulae-sdk-angular";

const program_id = 'p_cXIvNlxBqVpRFhAVIjatcb0MqP9LBIfpB2H';
const client_id = 'X9dOu1Ae7WcrKVVFnYzjxiE5bP6z3a8oDUA';
const loyaltymember_v2_api_host = 'api.loyalty-stage.nebulaeapp.com';
const loyaltymember_v2_api_path = 'members/v2';

export const environment = {
  production: false,
  program_id: program_id,
  client_id: client_id,
  api_v2_config: {
    api_host: loyaltymember_v2_api_host,
    api_path: loyaltymember_v2_api_path
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500,
  },
  sdk_config: {
    disable_logger: false,
    identity: {
      client_id: client_id,
      api_host: NebulaeHost.identity.api.getUrlObject("stage").host,
      api_host_port: NebulaeHost.identity.api.getUrlObject("stage").port,
      api_host_https: true,
      api_version: "v1",
    },
    loyaltymember: {
      client_id: client_id,
      api_host: NebulaeHost.loyaltymember.api.getUrlObject("stage").host,
      api_host_port: NebulaeHost.loyaltymember.api.getUrlObject("stage").port,
      api_version: "v1",
      api_host_https: true,
    },
  } as NebulaeSdkServiceConfig,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
