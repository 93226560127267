import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';

import {
  NebulaeIdentityService,
  NebulaeLoyaltyService,
  NebulaeLoyaltyMemberService,
  IdentityVerificationMethodType,
  IdentityVerifyType,
  IdentityCheckUserExistsResponse,
  IdentityPreVerifyType,
  Language,
} from '@nebulaeapp/nebulae-sdk-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );

  constructor(
    private identity: NebulaeIdentityService,
    private loyaltyMember: NebulaeLoyaltyMemberService,
    private ds: DataService,
    private nl: NebulaeLoyaltyService
  ) {
    this.identity
      .initAuth()
      .then(async () => {
        const access_token =
          this.loyaltyMember.getAuthorizationContent().access_token;
        this.loyaltyMember.setAuthorization({ bearer: access_token });
        const signupData = await this.ds.get(DataService.SIGNUP_DATA);
        if (signupData == null) {
          this.isAuthenticated.next(this.identity.isAuthenticated());
        } else {
          this.isAuthenticated.next(false);
        }
      })
      .catch((error) => {
        this.isAuthenticated.next(false);
      });
  }

  isLoggedIn() {
    let isAuthed = this.isAuthenticated.getValue();
    console.log('is Authed', isAuthed);
    return isAuthed;
  }

  async loadToken() {
    try {
      let authData = this.loyaltyMember.getAuthorizationContent();
      const access_token = authData.access_token;
      const refresh_token = authData.refresh_token;
      await this.ds.set('refresh_token', refresh_token);
      // console.log("access Token", access_token);
      this.loyaltyMember.setAuthorization({ bearer: access_token });
      const signupData = await this.ds.get(DataService.SIGNUP_DATA);

      if (signupData == null) {
        this.isAuthenticated.next(this.identity.isAuthenticated());
      } else {
        this.isAuthenticated.next(false);
      }
    } catch (error) {
      this.isAuthenticated.next(false);
    }
  }

  // logout
  async signOut(user_id, refresh_token): Promise<void> {
    this.identity;
    try {
      await this.identity.auth.signout({
        user_id: user_id,
        refresh_token: refresh_token,
      });
      this.isAuthenticated.next(false);
    } catch (error) {
    } finally {
      this.isAuthenticated.next(false);
    }
  }

  // check user exists
  async checkUserExists(i: {
    email: string;
    phone: {
      country_code: string;
      phone_number: string;
    };
  }): Promise<IdentityCheckUserExistsResponse | any> {
    try {
      if (i.phone.country_code === '852') {
        const result_phone = await this.identity.auth.checkUserExists({
          phone: i.phone,
        });

        const result_email = await this.identity.auth.checkUserExists({
          email: i.email,
        });

        if (result_email?.user_exists || result_phone?.user_exists) {
          if (result_email?.verified_at || result_phone?.verified_at) {
            return Promise.resolve({ user_exists: true, verified_at: true });
          } else {
            return Promise.resolve({ user_exists: true });
          }
        } else {
          return Promise.resolve({ user_exists: false });
        }
      } else {
        const result_email = await this.identity.auth.checkUserExists({
          email: i.email,
        });

        return Promise.resolve(result_email);
      }
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  // request signup verification
  async requestSignUpVerification(i: {
    email: string;
    phone: {
      country_code: string;
      phone_number: string;
    };
    lang: string;
  }): Promise<any> {
    try {
      if (i.phone.country_code === '852') {
        const result = await this.identity.auth.requestSignupVerification({
          phone: i.phone,
          verification_method: IdentityVerificationMethodType.SMS_OTP,
          lang: i.lang as Language,
        });

        return Promise.resolve(result);
      } else {
        const result = await this.identity.auth.requestSignupVerification({
          email: i.email,
          verification_method: IdentityVerificationMethodType.EMAIL_OTP,
          lang: i.lang as Language,
        });

        return Promise.resolve(result);
      }
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error);
      } else {
        return Promise.reject(error);
      }
    }
  }

  // request signin otp
  async requestSignInOtp(i: {
    email: string;
    phone: {
      country_code: string;
      phone_number: string;
    };
    lang: string;
  }): Promise<any> {
    try {
      if (i.phone.country_code === '852') {
        const result = await this.identity.auth.requestSignInOtp({
          phone: i.phone,
          verification_method: IdentityVerificationMethodType.SMS_OTP,
          lang: i.lang as Language,
        });

        return Promise.resolve(result);
      } else {
        const result = await this.identity.auth.requestSignInOtp({
          email: i.email,
          verification_method: IdentityVerificationMethodType.EMAIL_OTP,
          lang: i.lang as Language,
        });

        return Promise.resolve(result);
      }
    } catch (error: any) {
      return Promise.reject(error);
      // if (error.body && error.body.error) {
      //   return Promise.reject(error.body.error.message);
      // } else {
      //   return Promise.reject(error);
      // }
    }
  }

  async preVerify(i: {
    email: string;
    phone: {
      country_code: string;
      phone_number: string;
    };
    code: string;
  }): Promise<any> {
    try {
      if (i.phone.country_code === '852') {
        const result = this.identity.auth.preVerify({
          client_id: environment.client_id,
          phone: i.phone,
          code: i.code,
          pre_verify_type: IdentityPreVerifyType.SIGNUP,
          verification_method: IdentityVerificationMethodType.SMS_OTP,
        });
        return Promise.resolve(result);
      } else {
        const result = this.identity.auth.preVerify({
          client_id: environment.client_id,
          email: i.email,
          code: i.code,
          pre_verify_type: IdentityPreVerifyType.SIGNUP,
          verification_method: IdentityVerificationMethodType.EMAIL_OTP,
        });
        return Promise.resolve(result);
      }
    } catch (error: any) {
      return Promise.reject(error);
      // if (error.body && error.body.error) {
      //   return Promise.reject(error.body);
      // } else {
      //   return Promise.reject(error);
      // }
    }
  }

  async SignInOtp(i: {
    email: string;
    phone: {
      country_code: string;
      phone: string;
    };
    code: string;
  }): Promise<any> {
    try {
      if (i.phone.country_code === '852') {
        await this.identity.auth.passwordlessSignin({
          input: {
            verification_method: IdentityVerificationMethodType.SMS_OTP,
            code: i.code,
            type: IdentityVerifyType.SIGNIN,
            phone: i.phone,
          },
        });

        // const result = await this.loyalty.users.me();
        // return Promise.resolve(result);
      } else {
        await this.identity.auth.passwordlessSignin({
          input: {
            verification_method: IdentityVerificationMethodType.EMAIL_OTP,
            code: i.code,
            type: IdentityVerifyType.SIGNIN,
            email: i.email,
          },
        });
      }

      await this.loadToken();
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error);
      } else {
        return Promise.reject(error);
      }
    }
  }
}
