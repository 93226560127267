import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
declare var Html5Qrcode: any;
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit {
  html5QrCode: any;
  @Input() title: string;
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
    console.log('opened')
  }

  ionViewDidEnter() {
    this.html5QrCode = new Html5Qrcode("modalScanner");
    this.html5QrCode
    .start(
      { facingMode: "environment" },
      {
        fps: 10, // Optional, frame per seconds for qr code scanning
        qrbox: 250, // Optional, if you want bounded box UI
      },
      async (decodedText, decodedResult) => {
        // do something when code is read 
         
        await this.modalCtrl.dismiss(decodedText)

      },
      (errorMessage) => {
        // parse error, ignore it.
      }
    )
    .catch((err) => {
      // this.QrStarted = false;
      // Start failed, handle it.
    });
  }

  closeScanner(){
    this.modalCtrl.dismiss()
  }

  ionViewDidLeave() { 
      this.html5QrCode.stop(); 
  }

} 
