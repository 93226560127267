<ion-content>
<ion-grid>
  <ion-row>
    <ion-col> 
      <p class="ion-text-center">
        {{message}}
      </p>
    </ion-col>
  </ion-row>
</ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button *ngIf="!success" fill="clear" color="danger" (click)="closeModal()">
      <ion-icon name="close-circle-outline"></ion-icon>
    </ion-button>
    <ion-button *ngIf="success" fill="clear" color="success" (click)="closeModal()">
      <ion-icon name="checkmark-circle-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-footer>