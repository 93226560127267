import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Input() image: string;

  sliderOpts = {
    zoom: true
  };

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  ionViewDidEnter(){
    this.slides.update();
  }

  async zoom(zoomIn: boolean) {
    const slider = await this.slides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  closeModal(){
    this.modalCtrl.dismiss()
  }

}
