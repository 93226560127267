<ion-content> 
  <ion-slides [options]="sliderOpts">
    <ion-slide>
      <div class="swiper-zoom-container">
        <img src="{{image}}">
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>
<ion-footer>
  <ion-row>
    <ion-col size="4" class="ion-text-center">
      <ion-button (click)="zoom(false)" fill="clear" color="dark">
        <ion-icon name="remove" slot="start"></ion-icon>
        out
      </ion-button>
    </ion-col>
    <ion-col size="4" class="ion-text-center">
      <ion-button (click)="closeModal()" fill="clear" color="danger">
        <ion-icon name="close" slot="start"></ion-icon>
        close
      </ion-button>
    </ion-col>
    <ion-col size="4" class="ion-text-center">
      <ion-button (click)="zoom(true)" fill="clear" color="dark">
        <ion-icon name="add" slot="start"></ion-icon>
        in
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>