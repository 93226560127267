<ion-app dir="{{ textDir }}">
  <ion-split-pane contentId="menu-content" when="false">
    <ion-menu contentId="menu-content" class="app-sidemenu">
      <ion-header class="ion-no-border ion-padding-top">
        <ion-toolbar>
          <ion-buttons slot="start"> 
              <img class="menu-img" src="assets/menu-image.png"/> 
          </ion-buttons>
          <ion-buttons slot="end" class="ion-text-end">
            <ion-button (click)="closeMenu()" fill="clear" class="ion-no-padding">
              <img src="assets/back.png"/>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content> 
        <ion-list mode="md"> 
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/tabs/home">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                {{'SIDEMENU.HOME' | translate}}
              </ion-label>
            </ion-item>
            <ion-item routerLink="/tabs/location">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                {{'SIDEMENU.WHERE_TO_BUY' | translate}}
              </ion-label>
            </ion-item>
            <ion-item routerLink="/tabs/member">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                {{'SIDEMENU.MEMBER' | translate}}
              </ion-label>
            </ion-item>
            <ion-item (click)="openLink('product')">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                {{'SIDEMENU.PRODUCT' | translate}}
              </ion-label>
            </ion-item>
            <ion-item routerLink="/tabs/redemption">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                {{'SIDEMENU.REDEMPTION' | translate}}
              </ion-label>
            </ion-item>
            <!-- <ion-item routerLink="/tabs/event">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                EVENT
              </ion-label>
            </ion-item> -->
            <ion-item routerLink="/contact-us">
              <ion-label class="font-gotham-medium ion-text-uppercase">
                {{'SIDEMENU.CONTACT_US' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer class="ion-no-border">
        <ion-toolbar>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button fill="clear" mode="md" color="dark" (click)="logout()">
                <ion-label class="text-light-dark font-gotham ion-text-underline">
                  {{'SIDEMENU.LOGOUT' | translate}}
                </ion-label>
              </ion-button>
            </ion-col>
            <ion-col size="6" class="lang-buttons ion-text-end">
              <ion-button (click)="setLanguage('en_us')" class="ion-no-margin ion-no-padding font-gotham-light" color="dark" fill="clear">
                EN
              </ion-button>
              <ion-button class="ion-no-margin ion-no-padding" color="dark" fill="clear">
                |
              </ion-button>
              <ion-button (click)="setLanguage('zh_hk')" class="ion-no-margin ion-no-padding font-gotham-light" color="dark" fill="clear">
                繁
              </ion-button>
              <ion-button class="ion-no-margin ion-no-padding" color="dark" fill="clear">
                |
              </ion-button>
              <ion-button (click)="setLanguage('zh_cn')" class="ion-no-margin ion-no-padding font-gotham-light" color="dark" fill="clear">
                简
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
