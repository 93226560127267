import { Injectable } from '@angular/core';
import { CanActivate, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(public router: Router, public ds: DataService, public authService: AuthService){}
   
  canActivate(): Observable<boolean> {

    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => { 
        
        if (isAuthenticated) {
          
          this.router.navigate(['/tabs/home']);
          return false
          
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }
  
}
