import {
  APP_INITIALIZER,
  NgModule,
  Optional,
  PLATFORM_ID,
} from "@angular/core";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
// import { ServiceWorkerModule } from "@angular/service-worker";
// import { RESPONSE } from "@nguniversal/express-engine/tokens";
// import { isPlatformServer } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { environment } from "../environments/environment";
import { ReactiveFormsModule } from "@angular/forms";

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IonicStorageModule } from "@ionic/storage-angular";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";

//services
import {
  NebulaeSdkModule,
  NebulaeLoyaltyModule,
  NebulaeIdentityModule,
  NebulaeLoyaltyMemberModule,
} from "@nebulaeapp/nebulae-sdk-angular";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
// import { AngularFireModule } from "@angular/fire";
// import { AngularFireMessagingModule } from "@angular/fire/messaging";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserTransferStateModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,

    //sdk
    NebulaeSdkModule.forRoot(environment.sdk_config),
    NebulaeLoyaltyModule,
    NebulaeIdentityModule,
    NebulaeLoyaltyMemberModule,
    // ServiceWorkerModule.register("/combined-sw.js", {
    //   enabled: environment.production,
    //   // enabled: true,
    // }),
    // AngularFireModule.initializeApp({
    //   apiKey: "AIzaSyDGOAANo3ZAUM80n1zlMrRNUokBAD23SCQ",
    //   authDomain: "nebu-client-tatcha-app.firebaseapp.com",
    //   databaseURL: "",
    //   projectId: "nebu-client-tatcha-app",
    //   storageBucket: "nebu-client-tatcha-app.appspot.com",
    //   messagingSenderId: "809524676458",
    //   appId: "1:809524676458:web:84b2fddf32711bdcf30713",
    // }),
    // AngularFireMessagingModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (platformId: object, response: any) => {
    //     return () => {
    //       // In the server.ts we added a custom response header with information about the device requesting the app
    //       if (isPlatformServer(platformId)) {
    //         if (response && response !== null) {
    //           // Get custom header from the response sent from the server.ts
    //           const mobileDeviceHeader = response.get("mobile-device");

    //           // Set Ionic config mode?
    //         }
    //       }
    //     };
    //   },
    //   deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
    //   multi: true,
    // },
    InAppBrowser,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
