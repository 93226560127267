<ion-content>
  <ion-item class="title-header">
    <ion-button (click)="closeModal()" class="back-btn" slot="start" fill="clear">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-button>
    <h2>
      Select Reason
    </h2>
   
  </ion-item>
  <ion-list>
    <ion-radio-group [(ngModel)]="selectedReason">
      
      <ion-item *ngFor="let r of reason">
        <ion-label class="normal">{{r?.en_us}}</ion-label>
        <ion-radio slot="start" value="{{r.value}}"></ion-radio>
      </ion-item>
      
    </ion-radio-group>
  </ion-list>

</ion-content>

<ion-footer>
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-button (click)="closeModal()" fill="clear" color="dark">
        Cancel
      </ion-button>
    </ion-col>
    <ion-col class="ion-text-center">
      <ion-button (click)="selectReason()" fill="clear" color="dark">
        Okay
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>