import { Injectable } from '@angular/core';
import { CanActivate, Route, Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public ds: DataService, private authService: AuthService) {
   
   } 

   canActivate(): Observable<boolean>  {
    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        if (isAuthenticated) {
          
          return true;

        } else {
          // return true; // for development purpose only
          this.router.navigate(['/sign-in']);
          return false;
        }
      })
    );
  }
   
}
